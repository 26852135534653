import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "spacing-is-an-important--and-often-overlooked--part-of-visual-design-carbon-takes-a-lot-of-the-guesswork-out-of-spacing-to-help-designers-deliver-clear-functional-layouts"
    }}>{`Spacing is an important — and often overlooked — part of visual design. Carbon takes a lot of the guesswork out of spacing to help designers deliver clear, functional layouts.`}</h3>
    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Carbon’s two scales</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Spacing scale</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Layout scale</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Designing with space</AnchorLink>
      <AnchorLink mdxType="AnchorLink">FAQ</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "carbons-two-scales"
    }}>{`Carbon’s two scales`}</h2>
    <p>{`Carbon has two spacing scales; one for general spacing within components and the other for layout spacing. Both are designed to complement the existing components and typography throughout the system. The two scales have certain overlapping values that serve two different roles, so be mindful when picking a spacing token.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Scale`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Purpose`}</th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Spacing scale`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Used for smaller, more refined spacing needs, specifically within the context of a component (i.e. the space between a label and a text input).`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Layout scale`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Typically used for positioning components on a page (i.e., the space between a text field and a selector).`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "applying-spacing"
    }}>{`Applying spacing`}</h3>
    <p>{`Both the `}<em parentName="p">{`spacing`}</em>{` and the `}<em parentName="p">{`layout`}</em>{` scales can be applied to `}<inlineCode parentName="p">{`margin`}</inlineCode>{` or `}<inlineCode parentName="p">{`padding`}</inlineCode>{` properties. Likewise, these spacing tokens can be applied to both vertical and horizontal edges. The token takes the place of the values normally assigned to `}<inlineCode parentName="p">{`margin`}</inlineCode>{` and `}<inlineCode parentName="p">{`padding`}</inlineCode>{`. The following are all approved ways to syntactically apply Carbon spacing tokens:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`margin: $spacing-03;
margin: $layout-03 $layout-01;
margin: $spacing-07 0 $spacing-04 0;
margin-right: $layout-05;
padding: $spacing-04;
padding: $layout-05 $layout-03;
padding: $spacing-07 $spacing-04 0 $spacing-04;
`}</code></pre>
    <h2 {...{
      "id": "spacing-scale"
    }}>{`Spacing scale`}</h2>
    <p>{`Use the spacing scale when building individual components. It includes small increments needed to create appropriate spatial relationships for detail-level designs. This scale is applied and used within all Carbon components.`}</p>
    <div className="spacing-table">
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Token`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`rem`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`px`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Example`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`$spacing-01`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`0.125`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`2`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><img parentName="td" {...{
                "src": "/4041393ebfb762c7788e35bb4d8ac184/spacing-3.svg",
                "alt": "spacing-3xs example"
              }}></img></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`$spacing-02`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`0.25`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`4`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><img parentName="td" {...{
                "src": "/28e295396bb4cd2e3992fdab744c764e/spacing-4.svg",
                "alt": "spacing-2xs example"
              }}></img></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`$spacing-03`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`0.5`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`8`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><img parentName="td" {...{
                "src": "/bd4419d8f407c41d398add5b3bde168a/spacing-5.svg",
                "alt": "spacing-xs example"
              }}></img></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`$spacing-04`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`0.75`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`12`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><img parentName="td" {...{
                "src": "/06724bf3c03a56a21a6c796dd6756859/spacing-6.svg",
                "alt": "spacing-sm example"
              }}></img></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`1`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`16`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><img parentName="td" {...{
                "src": "/b56f60575930843bbcc471e99e45018b/spacing-7.svg",
                "alt": "spacing-md example"
              }}></img></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`$spacing-06`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`1.5`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`24`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><img parentName="td" {...{
                "src": "/2ee781f712d17e0ed36225113471e502/spacing-8.svg",
                "alt": "spacing-lg example"
              }}></img></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`$spacing-07`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`2`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`32`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><img parentName="td" {...{
                "src": "/842762a04b3086206fa1f32a99b14ae9/spacing-9.svg",
                "alt": "spacing-xl example"
              }}></img></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`$spacing-08`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`2.5`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`40`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><img parentName="td" {...{
                "src": "/fb48ea4de6676e4048db189bf2623a66/spacing-10.svg",
                "alt": "spacing-2xl example"
              }}></img></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`$spacing-09`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`3`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`48`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><img parentName="td" {...{
                "src": "/a69ea4de264f33a44fb4dd32b1e2aa82/spacing-11.svg",
                "alt": "spacing-3xl example"
              }}></img></td>
          </tr>
        </tbody>
      </table>
    </div>
    <br />
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "48.09782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAAB/0lEQVQoz62Sy2sTQRzHZ5vWR1HBix4EBb3pHyCIUE1Q8CgNKB78Dzx4CIg5CXox8XXQqycVPKgNVCkKRVHBB5TgJtlsdvNokn3FZJvsK7sz05+zk9CDoKf+4MN3HsyHeaHbp20UV+3naKb8eZjoKMOEpngJXfX/i6a4iU7Vmbl5BFA+hVE+SdBWZQ77aDvq7gKTZo9R3ql+9U6VVv3LbHeLporTVp2k9VqYbkteuvMX3aq/yPJSS3TPZ4/CjnwS78qdJXNc9OLBNyHOdr23bBtj6K5bMLBc6FsODAcBBA4Ff0Q4gTNJdyMEGgJYml29cRD25ZLRgXyK7OXCp4+WJ8Km/rqlaiBX6lH5l0zFokSbaof2DJuaep9a+oDq3R41tT61fzt47BFgWbyKYPf9c2T/vRSZ58L3q2+50DD1V7rVBrlWHcuyjGMkqYJLpRJPsSRiRVWwyrA37DDCETjOaC1eu3QrFN7lx5OLvH68xoXK2mDl+zMA+YcNRtMBve6CprJsuGA0PLBaAbg9AIcxMgE2PYjHGycQzD6+iOceXiCzXJhGwLPyKcg+v0Y/SB/JG0OJCt1yVNAqmMHaU9bFoNBmsFxirLSKwZPMoc15dtw9jJ1bz70wlW7Lt7kylUlfPPQyEwpsp4JW84V2ORA6lX8Tz7MPju6cpCh3hnDi+gP2Q64CN/wOlQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of spacing tokens applied",
            "title": "Example of spacing tokens applied",
            "src": "/static/3dfcaf1427135101a4db45ccc60a5325/fb070/spacing-1_revised.png",
            "srcSet": ["/static/3dfcaf1427135101a4db45ccc60a5325/d6747/spacing-1_revised.png 288w", "/static/3dfcaf1427135101a4db45ccc60a5325/09548/spacing-1_revised.png 576w", "/static/3dfcaf1427135101a4db45ccc60a5325/fb070/spacing-1_revised.png 1152w", "/static/3dfcaf1427135101a4db45ccc60a5325/c3e47/spacing-1_revised.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Example of spacing tokens applied</Caption>
      </Column>
    </Row>
    <h3 {...{
      "id": "other-spacing-options"
    }}>{`Other spacing options`}</h3>
    <p>{`There are a few other non-token methods that can be used for spacing elements. Each has its own unique use case.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Method`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Purpose`}</th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`center`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Used to fluidly center an element between two edges.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`auto`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Used on one side of an element to allow undefined space to automatically grow and shrink according to screen size. Typically used for asymmetrical fluid spacing.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`gutter`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Used to space items between the grid’s 12 columns`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "layout-scale"
    }}>{`Layout scale`}</h2>
    <p>{`The layout scale is used for arranging components and other UI parts into a full page layout. It has larger increments that are used to control the density of a design. Use the smaller layout tokens to create more dense compositions and direct relationships. Use the larger tokens to increase the amount of white space and to disassociate sections.`}</p>
    <div className="spacing-table">
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Token`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`rem`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`px`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Example`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`$layout-01`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`1`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`16`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><img parentName="td" {...{
                "src": "/0b958f78b302fa09290c3a3f8f8c14ae/spacing-12.svg",
                "alt": "layout-2xs example"
              }}></img></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`$layout-02`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`1.5`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`24`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><img parentName="td" {...{
                "src": "/93b960a78b67e12e4c96f94ba770cace/spacing-13.svg",
                "alt": "layout-xs example"
              }}></img></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`$layout-03`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`2`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`32`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><img parentName="td" {...{
                "src": "/606cf37578755f678f82af33025ac6a2/spacing-14.svg",
                "alt": "layout-sm example"
              }}></img></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`$layout-04`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`3`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`48`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><img parentName="td" {...{
                "src": "/b1b355c0ad1a19253e137535dd48fd3d/spacing-15.svg",
                "alt": "layout-md example"
              }}></img></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`$layout-05`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`4`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`64`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><img parentName="td" {...{
                "src": "/25744b93680b069f691d0598412140a2/spacing-16.svg",
                "alt": "layout-lg example"
              }}></img></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`$layout-06`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`6`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`96`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><img parentName="td" {...{
                "src": "/72564c21d8184751cc367d03f65e599a/spacing-17.svg",
                "alt": "layout-xl example"
              }}></img></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`$layout-07`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`10`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`160`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><img parentName="td" {...{
                "src": "/080511932c85866ffd74863a3a19bf7d/spacing-18.svg",
                "alt": "layout-2xl example"
              }}></img></td>
          </tr>
        </tbody>
      </table>
    </div>
    <br />
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "62.142857142857146%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsSAAALEgHS3X78AAACXklEQVQoz6WSTW/TQBCGLVniEv4bJz4kEH+gf6JJ4FChKrRcigRFqeilqAhxgqCiQEG09AAFkqZqEbSu7TiJv+Osd+3dYWabFIkrK43embH97L6zNubm5kwD1+PV1caDpSW4t7DA79brslatStI6au3fmJ+Xd2o1Wa9Vi8biInzc3r5KjDetlmksrzzRwNbW+/vPXr2GteebRXNjA1aaTXi0vg4ra2t/A3uz/OG5qqebm/B2d/cWMdo7O6bBylIDAaDRAQV7w6HoK6XOpFQOKoWNuT3NqU+1A0C9coQf9nz/NjE8zk3jR7ejgZZlNcZlCacI9MdjCCYTsAYDcIMAgiyDQRzrPGIMRmlKz5QbhpIpBU4Y3iRGluemcXh4eAHEzaAsCqGkhBLhPM9BolIthNAxfQc45wqfSaoF59eIIeiEM6Bt2w3A3aIwFAV+QMAQTwQIo36Gp4qwLjiHEsH+cKhyxjSwmAILIUyj2+tpoIvAPtohyw5ZjSKw0KaNIBdHcJYk4KA6WJNiX2FIHze3okhbxjGZRq97oIFnfbdR9AcQfPrMk509Of59ImOeyyTLJCsKmeJpkslEommtfhzLIEnKDE8bZ9l1YowZQ8sH50Db85ZhMIKgvQ3w5RuANwCBtmeL5oYz0jnNlOGlUWjLQtwgBteXMgWizbr3vSO8rbbtv/vgTX6deKMw9OIowr+BeyFqiHWaph5jzEuSpI/hYh3nnF8hRj4FUm6cus6l+OjnZUgnlc6LlxXr634FN9dxfHxcwYvSOf4NFQRVEHShnG73HKhZxpF1avzvmsH+AGh6oqTc5aJqAAAAAElFTkSuQmCC')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of layout tokens applied",
        "title": "Example of layout tokens applied",
        "src": "/static/64ec907def18d8eea2c671c7bd436898/fb070/layout_revised.png",
        "srcSet": ["/static/64ec907def18d8eea2c671c7bd436898/d6747/layout_revised.png 288w", "/static/64ec907def18d8eea2c671c7bd436898/09548/layout_revised.png 576w", "/static/64ec907def18d8eea2c671c7bd436898/fb070/layout_revised.png 1152w", "/static/64ec907def18d8eea2c671c7bd436898/fb104/layout_revised.png 1728w", "/static/64ec907def18d8eea2c671c7bd436898/8fefe/layout_revised.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <Caption mdxType="Caption">Example of layout tokens applied</Caption>
    <h3 {...{
      "id": "using-layout-scale-with-the-grid"
    }}>{`Using layout scale with the grid`}</h3>
    <p>{`The Carbon grid is the encouraged way to horizontally space UI elements. The layout scale is most commonly used for vertical spacing, but it can also be applied for horizontal spacing when appropriate, especially in the case of fixed spacing.`}</p>
    <h2 {...{
      "id": "designing-with-space"
    }}>{`Designing with space`}</h2>
    <p>{`Every part of a UI should be intentional including the empty space between elements. The amount of space between items creates relationships and hierarchy.`}</p>
    <h3 {...{
      "id": "creating-relationships"
    }}>{`Creating relationships`}</h3>
    <p>{`Elements in a design that are near each other are seen as being meaningfully related. As more space is added between elements, their perceived relationship weakens.`}</p>
    <p>{`Patterns created through spacing can also create relationships. Elements arranged in the same spacing pattern are seen as related pieces that have equal weight.`}</p>
    <p>{`Space can also be used to denote groups of associated information. This creates content sections on a page without having to use lines or other graphical elements as a divider.`}</p>
    <h3 {...{
      "id": "creating-hierarchy"
    }}>{`Creating hierarchy`}</h3>
    <p>{`Elements that have more spacing around them tend to be perceived as higher in importance than elements that have less space around them. Take this page for instance. The top level headers have more space surrounding them giving them focus and prominence. Then as the headers descend in importance they receive less space, signaling they are subordinate.`}</p>
    <p>{`Elements that are set close to each other can be easily overlooked. Users may see the grouping but not process each individual item. Therefore, if you have an element or content of high importance on the page, consider giving it extra surrounding space to help it attract focus.`}</p>
    <h3 {...{
      "id": "white-space"
    }}>{`White space`}</h3>
    <p>{`Empty space — also known as white space — is important in design. It can be used to break up sections on a page or to help create focus on certain element(s). White space helps with information processing; too much dense information can be disorienting or overwhelming for a user. Sections of a UI are allowed to be dense, but the whole page should not be crowded; there should be white space to let the user’s eye rest.`}</p>
    <h2 {...{
      "id": "faq"
    }}>{`FAQ`}</h2>
    <h5 {...{
      "id": "are-spacing-increments-outside-of-the-scale-allowed"
    }}>{`Are spacing increments outside of the scale allowed?`}</h5>
    <p>{`There are always exceptions to the rule, but deviating from the spacing scales should be avoided whenever possible.`}</p>
    <h5 {...{
      "id": "can-i-still-use-the-carbon-grid-for-horizontal-spacing"
    }}>{`Can I still use the Carbon grid for horizontal spacing?`}</h5>
    <p>{`Yes, in fact it’s encouraged.`}</p>
    <h5 {...{
      "id": "can-i-still-use-percentages-for-spacing"
    }}>{`Can I still use percentages for spacing?`}</h5>
    <p>{`Percentages like 50% (1/2) or 33% (1/3) are still acceptable ways to divide a page. Percentages can all still be used to control max and min widths. However, using percentages for padding or margin inside of a component or element should be avoided (ex. `}<inlineCode parentName="p">{`padding-right: 10%`}</inlineCode>{`). The exception to this is if you’re applying it to fit the 5% grid margins.`}</p>
    <h5 {...{
      "id": "are-the-spacing-tokens-responsive"
    }}>{`Are the spacing tokens responsive?`}</h5>
    <p>{`No, the tokens themselves do not change values based on the screen size. However, it is acceptable at page breakpoints to jump a step(s) on the spacing scale to fit spacing needs (i.e., at 1440 px `}<inlineCode parentName="p">{`padding-right: $spacing-05`}</inlineCode>{` but at breakpoint 768px `}<inlineCode parentName="p">{`padding-right: $spacing-03`}</inlineCode>{`).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      